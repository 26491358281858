import BannerCard from "@/components/common/banner-card";
import Carousel from "@/components/ui/carousel/carousel";
import { SwiperSlide } from "swiper/react";
import { useSsrCompatible } from "@/utils/use-ssr-compatible";
import { useWindowSize } from "@/utils/use-window-size";
const HeroBlock = ({
  data,
  slidesPerView = 1,
  variant,
  loading = false,
  ...rest
}) => {
  const { width } = useSsrCompatible(useWindowSize(), { width: 0, height: 0 });
  return (
    <div className="heroBannerOne relative mb-0 mx-auto overflow-hidden bg-white w-full">
      <Carousel
        loop={true}
        slidesPerView={slidesPerView}
        centeredSlides={false}
        // autoplay={{
        //   delay: 10000,
        // }}
        className="mx-0 w-full"
        buttonGroupClassName="hidden"
        {...rest}
        navigation={false}
      >
        {loading ? (
          <SwiperSlide className="carouselItem w-full">
            <BannerCard
              banner={{
                data: {
                  attributes: {
                    url: "/assets/placeholder/products/product-list.svg",
                  },
                },
              }}
              href={"#"}
              effectActive={false}
              promo={false}
              index={1}
              activeSlide={true}
              variant={variant}
            />
          </SwiperSlide>
        ) : (
          data?.map((row, key) => {
            const imageToDisplay = width < 767 ? row.mobileImage : row.image;
            return (
              <SwiperSlide
                className="carouselItem w-full"
                key={`banner--key-${row?.id}`}
              >
                {({ isActive }) => (
                  <BannerCard
                    banner={imageToDisplay}
                    href={row.link}
                    effectActive={true}
                    promo={true}
                    index={key + 1}
                    activeSlide={isActive}
                    variant={variant}
                  />
                )}
              </SwiperSlide>
            );
          })
        )}
      </Carousel>
    </div>
  );
};

export default HeroBlock;
