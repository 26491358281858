import cn from "classnames";
import Link from "next/link";
import Image from "next/image";

import usePrice from "@/framework/product/use-price";
import { getLowestPricedVariant } from "@/framework/utils/get-lowest-priced-variant";
import { event } from "@/lib/ga";
import { sanitize } from "@/framework/utils/sanitize";
import { useSsrCompatible } from "@/utils/use-ssr-compatible";
import { useWindowSize } from "@/utils/use-window-size";

const ProductCardGrid = ({ product }) => {
  const placeholderImage = `/images/product-list.svg`;
  const lowestPricedVariant = getLowestPricedVariant(product?.variants ?? null);
  const { price, basePrice, discount } = usePrice({
    amount: lowestPricedVariant?.salePrice,
    baseAmount: lowestPricedVariant?.compareAtPrice,
    currencyCode: "INR",
  });
  const productImages = product?.images?.data.map((row) => sanitize(row)) ?? [];
  const { width } = useSsrCompatible(useWindowSize(), { width: 0, height: 0 });
  return (
    <div className="w-full lg:h-[290px] h-[270px] shrink-0 flex flex-col relative">
      <Link
        onClick={() => {
          if (product) {
            const payload = {
              item_list_id: product?.slug,
              item_list_name: product.name,
              items: [{ item_id: product.id, item_name: product.name }],
            };
            event("select_item", payload);
          }
        }}
        className={cn(
          `shrink-0 group overflow-hidden w-full flex rounded-md cursor-pointer flex-col items-center justify-start transition duration-200 ease-in-out`
        )}
        href={`/products/${product?.slug}`}
        title={product?.name}
      >
        <div
          className={
            "overflow-hidden mb-3 flex items-center justify-center relative lg:max-w-[16vw] lg:w-full lg:h-[210px] md:w-[173px] md:h-[173px] w-[90%] h-[140px]"
          }
        >
          <Image
            src={
              productImages[0]?.formats?.medium?.url ??
              productImages[0]?.url ??
              placeholderImage
            }
            fill
            // quality={width > 767 ? 90 : 60}
            alt={product?.name ?? productImages[0]?.alternativeText}
            sizes="(max-width: 768px) 200px, (max-width: 1200px) 300px, 500px"
            className={`w-full ${
              !product ? "object-cover" : "object-contain"
            } object-center transition duration-200 ease-in bg-white hover:scale-95 transform`}
          />
        </div>
        <div className={"w-full flex flex-col items-center justify-start"}>
          <h2
            className={`w-full font-avenir font-normal text-center text-wrap text-[#1A202C] tracking-[0.5px] capitalize mb-1 h-auto lg:text-base text-sm flex items-start justify-center leading-tight`}
          >
            {product?.name ?? "loading..."}
          </h2>
          <div className="absolute top-0 md:top-2 3xl:top-3 right-3.5 md:right-3 3xl:right-4 flex flex-col gap-y-1 items-start">
            {discount && (
              <span className="bg-red text-white text-[10px] md:text-sm leading-5 rounded-md inline-block px-1 sm:px-1.5 xl:px-2 py-0.5 md:py-1">
                <p>
                  <span className="inline">SALE</span>
                </p>
              </span>
            )}
          </div>
          <div
            className={`font-semibold flex items-center justify-center text-sm flex-wrap gap-x-2 lg:text-lg`}
          >
            {product?.outOfStock ? (
              <span className="text-sm leading-5 pl-3 font-semibold text-red">
                Out of stock
              </span>
            ) : (
              <>
                <span className="inline-block text-sm">
                  {price ? `from Rs. ${price.toLocaleString()}` : "...loading"}
                </span>
                {discount && (
                  <del className="text-sm text-[#A30A0A]">
                    Rs. {basePrice?.toLocaleString()}
                  </del>
                )}
              </>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCardGrid;
