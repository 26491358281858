import { useRef, useState, useEffect } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import cn from "classnames";
import {
  Navigation,
  Scrollbar,
  Pagination,
  Autoplay,
  Grid,
  FreeMode,
  Mousewheel,
} from "swiper/modules";
import { Swiper } from "swiper/react";

import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/grid";

const Carousel = ({
  children,
  className = "",
  buttonGroupClassName = "",
  prevActivateId = "",
  nextActivateId = "",
  paginationFractionId = "",
  prevButtonClasses = "left-0",
  nextButtonClasses = "right-0",
  buttonSize = "default",
  paginationVariant = "default",
  paginationPosition,
  breakpoints,
  loop = true,
  navigation = true,
  pagination = false,
  autoplay = false,
  type = "circle",
  slidesPerView = "auto",
  isFraction = false,
  ...props
}) => {
  const dir = "";
  const [swiper, setSwiper] = useState();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);
  // const prevRef = useRef(null);
  // const nextRef = useRef(null);
  const classPagination = paginationPosition
    ? `pagination-${paginationPosition}`
    : "";

  let nextButtonClassName = cn(
    "w-12 h-12 text-sm md:text-base lg:text-lg text-black flex items-center justify-center rounded bg-white shrink-0 transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none transform shadow-navigation",
    {
      "rounded-full": type === "circle",
      "text-sm md:text-base lg:text-xl 3xl:text-2xl": buttonSize === "default",
      "!static": type === "list",
    },
    nextButtonClasses
  );

  let prevButtonClassName = cn(
    "w-12 h-12 text-sm md:text-base lg:text-lg text-black flex items-center justify-center rounded bg-white shrink-0 transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none transform shadow-navigation",
    {
      "rounded-full": type === "circle",
      "text-sm md:text-base lg:text-xl 3xl:text-2xl": buttonSize === "default",
      "!static": type === "list",
    },
    prevButtonClasses
  );
  return (
    <div
      className={`carouselWrapper relative flex items-center w-full ${className} ${classPagination} ${
        paginationVariant === "circle" ? "dotsCircle" : ""
      } ${type === "list" ? "!static" : ""}`}
    >
      {navigation == true && (
        <button
          ref={prevRef}
          className={prevButtonClassName}
          aria-label="prev-button"
        >
          <IoIosArrowBack />
        </button>
      )}
      <Swiper
        modules={[
          Navigation,
          Autoplay,
          Pagination,
          Scrollbar,
          Grid,
          FreeMode,
          Scrollbar,
          Mousewheel,
        ]}
        loop={loop}
        slidesPerView={slidesPerView}
        autoplay={autoplay}
        breakpoints={breakpoints}
        pagination={pagination}
        // dir={dir}
        // key={dir}
        // navigation={navigation}
        onSwiper={setSwiper}
        navigation={{
          prevEl: prevRef.current, // Assert non-null
          nextEl: nextRef.current, // Assert non-null
        }}
        {...props}
      >
        {children}
      </Swiper>
      {navigation == true && (
        <button
          ref={nextRef}
          className={nextButtonClassName}
          aria-label="next-button"
        >
          <IoIosArrowForward />
        </button>
      )}
    </div>
  );
};

export default Carousel;
