import React, { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import SectionHeader from "@/components/common/section-header";
import Carousel from "@/components/ui/carousel/carousel";
import Image from "next/image";
const placeholderImage = `/images/product-list.svg`;
import { FaInstagram } from "react-icons/fa";
import { m } from "@/lib/framer-motion";
import { useSsrCompatible } from "@/utils/use-ssr-compatible";
import { useWindowSize } from "@/utils/use-window-size";
import Alert from "@/components/ui/alert";

export default function InstagramFeed() {
  const [instaFeed, setInstaFeed] = useState(null);
  const [error, setError] = useState(false);
  useEffect(() => {
    const abortController = new AbortController();
    async function getInstaFeed() {
      try {
        const response = await fetch(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,permalink&limit=32&access_token=IGQWRQZAzQ3b21KRVVRZADZABSVQ4MDlSclZAyNE5yS3NuZA3JMWHBINjl2QmdQWjBFU0Q2M0ZAPck82QVFFaXZAxMVBObktRaFR1dXBkYjNMZAVZAHYktqTmp1T0VGLTlIaTdHb29tQXdjeG84WG10OHMxRFpWZADlDaTd4VkEZD`
        );
        if (response.ok) {
          const data = await response.json();

          setInstaFeed(
            data.data.filter(
              (item) =>
                item.media_type === "IMAGE" ||
                item.media_type === "CAROUSEL_ALBUM"
            )
          );
        } else {
          throw new Error("Failed to fetch data from Instagram API");
        }
      } catch (err) {
        console.error("Error:", err);
        setError("Failed to load instagram feed.");
      }
    }
    getInstaFeed();
    return () => {
      // cancel pending fetch request on component unmount
      abortController.abort();
    };
  }, []);

  const breakpoints = {
    1280: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 3,
    },
    325: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
  };
  return (
    <div className="w-full flex flex-col py-0 px-4 md:px-8 lg:px-[5vw] md:mt-0 lg:mt-6 mt-12 overflow-hidden">
      <SectionHeader sectionHeading={"Instagram"} className="pb-0.5 mb-6" />
      {error ? (
        <div className="col-span-full">
          <Alert message={error} />
        </div>
      ) : (
        <div className="w-full overflow-hidden">
          <Carousel
            slidesPerView={3}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            // autoplay={{
            //   delay: 4000,
            //   // disableOnInteraction: false,
            //   // pauseOnMouseEnter: true,
            // }}
            navigation={false}
            className="familyCarousel"
            breakpoints={breakpoints}
            isFraction={true}
          >
            {!instaFeed
              ? Array.from({ length: 6 }).map((_, idx) => {
                  return (
                    <SwiperSlide key={`card-circle-${idx}`}>
                      <Slide data={null} />
                    </SwiperSlide>
                  );
                })
              : instaFeed?.map((feed, key) => (
                  <SwiperSlide className="instaItem" key={"feed" + key}>
                    {/* <Feed key={feed.id} feed={feed} /> */}
                    <Slide data={feed} />
                  </SwiperSlide>
                ))}
          </Carousel>
        </div>
      )}
      {/* <div className="w-full overflow-hidden">
        <Carousel
          slidesPerView={2}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          className="familyCarousel"
          breakpoints={breakpoints}
          isFraction={true}
        >
          {instaFeed?.map((feed, key) => (
            <SwiperSlide className="carouselItem" key={"feed" + key}>
              <Slide data={feed} />
            </SwiperSlide>
          ))}
        </Carousel>
      </div> */}
    </div>
  );
}
const Slide = ({ data }) => {
  const { width } = useSsrCompatible(useWindowSize(), { width: 0, height: 0 });
  const imageUrl = data?.media_url ?? placeholderImage;
  return (
    <m.a
      initial={{ opacity: 0 }}
      animate={{ opacity: 100 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      href={data?.permalink ?? "#"}
      target="_blank"
      rel="noreferrer noopener"
      className="group rounded-md flex flex-col shrink-0 overflow-hidden w-full lg:h-[18vw] md:h-48 h-40"
    >
      <Image
        src={imageUrl}
        alt={`Insta Slide ${data?.id}` ?? "Insta Slide"}
        className="w-full h-full object-cover object-top rounded-md"
        fill
        quality={width > 771 ? 30 : 30}
        sizes="(max-width: 768px) 300px, (max-width: 1200px) 300px, 200px"
      />
      <div
        className={
          "absolute top left bg-black/50 w-full h-full opacity-0 transition-opacity duration-300 group-hover:opacity-50 rounded-md"
        }
      />
      <div className="absolute top left h-full w-full flex items-center justify-center">
        <FaInstagram className="text-white text-base sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl transform opacity-0 scale-400 transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:scale-100" />
      </div>
    </m.a>
  );
};
