import Card from "@/components/common/card";
import SectionHeader from "@/components/common/section-header";
import Carousel from "@/components/ui/carousel/carousel";
import Alert from "@/components/ui/alert";
import { SwiperSlide } from "swiper/react";
import { useQuery } from "@/framework/queries/graphql";
import { QUERY_ENDPOINTS } from "@/framework/utils/query-endpoints";
import { sanitize } from "@/framework/utils/sanitize";
const CategoryBlock = ({
  sectionHeading,
  roundedItemCount,
  slugPrefix = "",
}) => {
  const breakpoints = {
    1720: {
      slidesPerView: 7,
      spaceBetween: 20,
    },
    1400: {
      slidesPerView: 6,
      spaceBetween: 20,
    },
    1300: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
    1025: {
      slidesPerView: 5,
      spaceBetween: 28,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    500: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    0: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
  };
  const [{ data, fetching: loading, error }] = useQuery({
    query: QUERY_ENDPOINTS.PRODUCT_CATEGORIES,
    requestPolicy: "network-only",
  });
  return (
    <div className="bg-gray-200 w-full h-auto flex flex-col py-12 px-2 md:px-8 lg:px-[3vw] mt-12 md:mt-0">
      {sectionHeading && (
        <div className="w-full flex items-center justify-center lg:px-[2vw]">
          <SectionHeader
            sectionHeading={sectionHeading}
            className="pb-0.5 mb-6 px-2"
            textClassName="text-center uppercase m-auto font-avenir px-8 bg-gray-200 z-[1]"
          />
        </div>
      )}
      {error ? (
        <Alert message={error?.message} />
      ) : (
        <Carousel
          buttonGroupClassName="-mt-4 md:-mt-5 xl:-mt-7 lg:px-8"
          // autoplay={{
          //   delay: 3000,
          // }}
          className="productCarousel"
          breakpoints={breakpoints}
        >
          {loading
            ? Array.from({ length: roundedItemCount || 7 }).map((_, idx) => {
                return (
                  <SwiperSlide key={`card-rounded-${idx}`}>
                    <Card item={null} href={`#`} effectActive={true} />
                  </SwiperSlide>
                );
              })
            : data?.productCategories?.data?.map((category) => (
                <SwiperSlide key={`category--key-${category.id}`}>
                  <Card
                    item={sanitize(category)}
                    href={`${slugPrefix}${sanitize(category).name}`}
                    effectActive={true}
                  />
                </SwiperSlide>
              ))}
        </Carousel>
      )}
    </div>
  );
};

export default CategoryBlock;
