import React from "react";
import { useTimer } from "react-timer-hook";
import Section from "@/components/ui/section";
import Button from "@/components/ui/button";
import Link from "next/link";
export default function Timer({ data }) {
  let expiryTimestamp = new Date(data?.expiry);
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds());

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      //   setExpire(true);
    },
  });

  return (
    <Section className="bg-gray-200 overflow-hidden py-8 md:py-12">
      {data?.title && (
        <h4 className="text-black lg:text-3xl font-semibold md:text-2xl text-lg text-center capitalize">
          {data?.title}
        </h4>
      )}
      <div className="mt-5 flex flex-row items-center justify-center gap-6">
        <ValueBox label="Days" value={days} />
        <ValueBox label="Hours" value={hours} />
        <ValueBox label="Mins" value={minutes} />
        <ValueBox label="Secs" value={seconds} />
      </div>
      <Link href={data?.buttonLink ?? "#"} className="mx-auto">
        <Button className="w-fit mx-auto mt-8">
          {data?.buttonText ?? "Click Here"}
        </Button>
      </Link>
    </Section>
  );
}
const ValueBox = ({ value, label }) => {
  const valueClass = "text-black text-3xl lining-nums";
  const labelClass = "text-black text-lg uppercase lining-nums";
  return (
    <div className="flex flex-col items-center justify-center">
      <span className={valueClass}>{value}</span>
      <span className={labelClass}>{label}</span>
    </div>
  );
};
